import { Helmet, HelmetProvider } from "react-helmet-async";

export interface MetaTagsProperties {
  title: string;
  description: string;
  route?: string;
}

export function MetaTags(properties: MetaTagsProperties): JSX.Element {
  const { title, description, route } = properties;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="og:title" content={title} />
        <meta name="twitter:title" content={title} />

        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="toolkitai" />
        <meta name="twitter:creator" content="toolkitai" />

        <meta name="og:site_name" content="syllabyte.ai" />
        <meta name="og:type" content="website" />

        {route && (
          <meta name="og:url" content={`https://www.syllabyte.ai/${route}`} />
        )}
      </Helmet>
    </HelmetProvider>
  );
}
