import { Form, Formik, FormikProps, FormikState } from "formik";
import { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { ListItem } from "../../types/platform/ListItem";
import { Profile } from "../../types/platform/Profile";
import { onboardProfile } from "../../util/ApiIntegration";
import { BOOKING_FORM_URL } from "../../util/Constants";
import { MARKETLIST } from "../../util/MarketsList";
import { Button } from "../fields/Button";
import { MarketPicker } from "../fields/MarketPicker";
import { TextInputField } from "../fields/TextInputField";
import { Toggle } from "../fields/Toggle";

export interface OnboardingFormProperties {
  profile: Profile;
  onboardedProfile?: (profile: Profile) => void;
}

export const OnboardingForm = (
  properties: OnboardingFormProperties
): JSX.Element => {
  const { profile, onboardedProfile } = properties;

  // if they user came via an ad source load it up
  const adSource = localStorage.getItem("syllabyte.adSource") || undefined;
  const adSourceId = localStorage.getItem("syllabyte.adSourceId") || undefined;

  // default to marketing true on first load
  useEffect(() => {
    if (profile.marketingConsent == false) {
      profile.marketingConsent = true;
    }
  }, []);

  const onboardingValidation = useMemo(() => {
    return Yup.object().shape({
      market: Yup.string().required("You need to select your location"),
    });
  }, []);

  const handleSubmit = async (values: Profile) => {
    const onboardedResult = await onboardProfile(values, adSource, adSourceId);

    //remove ad source from local storage
    localStorage.removeItem("syllabyte.adSource");
    localStorage.removeItem("syllabyte.adSourceId");

    if (onboardedProfile) {
      onboardedProfile(onboardedResult);
    }
  };

  return (
    <>
      {profile && (
        <div>
          <Formik
            initialValues={profile}
            validationSchema={onboardingValidation}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnBlur
          >
            {({
              isSubmitting,
              setFieldValue,
            }: FormikProps<Profile> & FormikState<Profile>) => (
              <Form>
                <div>
                  <div className="col-span-6 mb-4">
                    <MarketPicker
                      autoDetect={true}
                      label="Required - Language & Location"
                      onChange={(market: ListItem) => {
                        setFieldValue("market", market.key);
                        profile.market = market.key;
                      }}
                      selectedValue={
                        MARKETLIST.find(
                          option => option.key === profile.market
                        ) as ListItem
                      }
                    />
                    <p className="mt-2 text-sm text-gray-600">
                      Used for data compliance and personalisation. If your
                      region is not listed, please select the closest match for
                      your language.
                    </p>
                  </div>

                  <div className="col-span-6 mb-4">
                    <TextInputField
                      name="name"
                      label="Name"
                      helper="optional for personalisation"
                    />
                  </div>

                  <ul className="divide-y divide-gray-200">
                    <li key="marketing" className="py-4">
                      <Toggle
                        label="Product Updates & General Marketing Emails"
                        caption="We'll email you details about the latest product updates."
                        value={profile.marketingConsent}
                        onChange={async (value: boolean) => {
                          profile.marketingConsent = value;
                          setFieldValue("marketingConsent", value);
                        }}
                      ></Toggle>
                    </li>
                    <li key="tosConsent" className="py-4">
                      <Toggle
                        labelChildren={
                          <div>
                            <label className="block font-semibold">
                              I accept the terms of service and privacy policy
                            </label>
                            I have read, understood, and accept the{" "}
                            <a
                              className="text-syllabyte-blue hover:underline"
                              target="_blank"
                              href="https://www.syllabyte.ai/terms?utm_source=onboard&utm_medium=signupterms&utm_id=syllabyte"
                            >
                              terms of service
                            </a>{" "}
                            and the{" "}
                            <a
                              className="text-syllabyte-blue hover:underline"
                              target="_blank"
                              href="https://www.syllabyte.ai/privacy?utm_source=onboard&utm_medium=signupterms&utm_id=syllabyte"
                            >
                              privacy policy
                            </a>
                            .
                          </div>
                        }
                        value={profile.tosConsent}
                        onChange={async (value: boolean) => {
                          profile.tosConsent = value;
                          setFieldValue("tosConsent", value);
                        }}
                      ></Toggle>
                    </li>
                  </ul>
                </div>
                <div className="text-right mt-8">
                  <Button
                    to={BOOKING_FORM_URL}
                    className="text-center"
                    loadingColor="white"
                    variant="syllabyteBlue"
                  >
                    Book a Demo to get Access
                  </Button>
                </div>
                <div className="text-right mt-8">
                  <Button
                    type="submit"
                    variant="syllabyteBlue"
                    isLoading={isSubmitting}
                    disabled={true}
                  >
                    [Disabled] Finish setting up my account
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
