import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import React, { createContext, useContext, useEffect } from "react";
import { updateProfile } from "./ApiIntegration";
import { ProfileContext } from "./ProfileContext";

type DarkModeContextType = {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
};

const DarkModeContext = createContext<DarkModeContextType | undefined>(
  undefined
);

export const DarkModeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { profile } = useContext(ProfileContext);

  // Initialize from localStorage first, then update when profile loads
  /*
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode
      ? JSON.parse(savedMode)
      : window.matchMedia("(prefers-color-scheme: dark)").matches;
  });
  */
  const isDarkMode = false;

  // Update when profile loads
  /*
  useEffect(() => {
    if (profile && profile.darkMode !== undefined) {
      setIsDarkMode(profile.darkMode);
    }
  }, [profile]);
  */

  // Apply dark mode class and save to localStorage
  useEffect(() => {
    const root = window.document.documentElement;
    const transition = () => {
      root.classList.add("transition");
      root.classList.add("duration-300");
      window.setTimeout(() => {
        root.classList.remove("transition");
        root.classList.remove("duration-300");
      }, 300);
    };

    transition();

    if (isDarkMode) {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }

    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const toggleDarkMode = async () => {
    const newMode = !isDarkMode;
    // setIsDarkMode(newMode);

    // If user is logged in, update their profile
    if (profile?.id) {
      try {
        await updateProfile({
          ...profile,
          darkMode: newMode,
        });
      } catch (error) {
        console.error("Failed to update dark mode preference:", error);
      }
    }
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (context === undefined) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
};

export default function DarkModeToggle() {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <button
      onClick={toggleDarkMode}
      type="button"
      className="p-2 rounded-full hover: focus:outline-none focus:ring-2 focus:ring-syllabyte-blue transition-colors duration-300"
      aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
    >
      {isDarkMode ? (
        <SunIcon className="h-6 w-6" />
      ) : (
        <MoonIcon className="h-6 w-6" />
      )}
      <span className="sr-only">
        {isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
      </span>
    </button>
  );
}
