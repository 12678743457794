/**
 * Formats a number of bytes into a human-readable string.
 * @param bytes - The number of bytes to format
 * @param decimals - The number of decimal places to show
 * @returns A formatted string representing the size
 * @example
 * formatBytes(1024) // "1 KB"
 * formatBytes(1234567, 2) // "1.18 MB"
 */
export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const index = Math.floor(Math.log(bytes) / Math.log(k));
  return `${Number.parseFloat((bytes / Math.pow(k, index)).toFixed(dm))} ${sizes[index]}`;
}

/**
 * Validates an email address using a simple regex pattern.
 * Note: This is a basic validation and may not catch all edge cases.
 * @param email - The email address to validate
 * @returns True if the email is valid, false otherwise
 * @example
 * isEmail("user@example.com") // true
 * isEmail("invalid-email") // false
 */
export const isEmail = (email: string): boolean => {
  const emailRegex = /^[\w.-]+@[\w.-]+\.\w{2,}$/;
  return emailRegex.test(email);
};

/**
 * Returns the current URL's query parameters as a URLSearchParams object.
 * @returns URLSearchParams object
 * @example
 * // If URL is "https://example.com?name=John&age=30"
 * const params = useQueryParameters();
 * console.log(params.get("name")); // "John"
 * console.log(params.get("age")); // "30"
 */
export function useQueryParameters(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

/**
 * Pauses execution for a specified number of milliseconds.
 * @param milliseconds - The number of milliseconds to sleep
 * @returns A promise that resolves after the specified time
 * @example
 * async function example() {
 *   console.log("Start");
 *   await sleep(2000);
 *   console.log("2 seconds later");
 * }
 */
export async function sleep(milliseconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Formats a duration in milliseconds into a human-readable string.
 * @param ms - The duration in milliseconds
 * @returns A formatted string representing the duration
 * @example
 * formatDuration(123456789) // "1 day, 10 hours, 17 minutes, 36 seconds, 789 milliseconds"
 * formatDuration(60000) // "1 minute"
 */
export const formatMillisecondDuration = (ms: number): string => {
  if (ms < 0) ms = -ms;
  const time = {
    day: Math.floor(ms / 86_400_000),
    hour: Math.floor(ms / 3_600_000) % 24,
    minute: Math.floor(ms / 60_000) % 60,
    second: Math.floor(ms / 1000) % 60,
    millisecond: Math.floor(ms) % 1000,
  };
  return Object.entries(time)
    .filter(([, value]) => value !== 0)
    .map(([unit, value]) => `${value} ${value === 1 ? unit : unit + "s"}`)
    .join(", ");
};

export function formatSecondsDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes > 0) {
    return `${minutes}m ${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}s `;
  }
  return `${remainingSeconds}s `;
}

/**
 * Formats a Date object into a string in the format "YYYY-MM-DD_HH-mm".
 * @param dateTime - The Date object to format (defaults to current date/time)
 * @returns A formatted string representing the date and time
 * @example
 * getDateTimeAsString(new Date("2023-04-15T14:30:00")) // "2023-04-15_14-30"
 * getDateTimeAsString() // Returns current date/time, e.g., "2023-04-15_14-30"
 */
export const getDateTimeAsString = (dateTime: Date = new Date()): string => {
  const pad = (number_: number) => number_.toString().padStart(2, "0");
  return `${dateTime.getFullYear()}-${pad(dateTime.getMonth() + 1)}-${pad(dateTime.getDate())}_${pad(dateTime.getHours())}-${pad(dateTime.getMinutes())}`;
};

/**
 * Formats a Date object into a string in the format "DD-MMM-YYYY" (e.g., "13-Nov-2024").
 * @param date - The Date object to format
 * @returns A formatted string representing the date with short month name
 * @example
 * formatDateToShortDisplay(new Date("2024-11-13")) // "13-Nov-2024"
 * formatDateToShortDisplay(new Date("2023-01-05")) // "05-Jan-2023"
 */
export const formatDateToShortDisplay = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

/**
 * Formats a timestamp string to ensure it has three decimal places for milliseconds.
 * @param timestamp - The timestamp string in the format "HH:mm:ss.SSS"
 * @returns A formatted timestamp string with three decimal places for milliseconds
 * @example
 * formatTimestamp("12:34:56.7") // "12:34:56.700"
 * formatTimestamp("01:23:45.678") // "01:23:45.678"
 */
export const formatTimestamp = (timestamp: string): string => {
  const [time, ms = "000"] = timestamp.split(".");
  return `${time}.${ms.padEnd(3, "0")}`;
};

/**
 * Trims a timespan string from C# API to remove the decimal places.
 * @param timespan - The timespan string in the format "HH:mm:ss.SSSSSSS"
 * @returns A trimmed timespan string with no decimal places
 * @example
 * trimTimespan("00:00:04.2200000") // "00:00:04"
 * trimTimespan("01:23:45.6789000") // "01:23:45"
 * trimTimespan("01:23:45.6") // "01:23:45"
 */
export function trimTimespan(timespan?: string): string {
  if (!timespan) {
    return "";
  }

  return timespan.split(".")[0];
}

/**
 * Utility functions for working with timespans.
 */

/**
 * Converts a timespan string to milliseconds.
 * @param {string} timespan - The timespan string to convert. Expected format is "HH:MM:SS" or "HHH:MM:SS" for spans over 99 hours.
 * @returns {number} The timespan in milliseconds.
 * @throws {Error} If the input string is not in the correct format.
 */
export function convertTimespanStringToMilliseconds(timespan?: string): number {
  if (!timespan) {
    return 0;
  }

  const parts = timespan.split(":");

  if (parts.length !== 3) {
    throw new Error("Invalid timespan format. Expected HH:MM:SS or HHH:MM:SS");
  }

  const [hours, minutes, seconds] = parts.map(part => {
    const numberPart = Number.parseInt(part, 10);
    if (Number.isNaN(numberPart)) {
      throw new TypeError(`Invalid timespan part: ${part}`);
    }
    return numberPart;
  });

  if (minutes >= 60 || seconds >= 60) {
    throw new Error("Minutes and seconds must be less than 60");
  }

  return (hours * 3600 + minutes * 60 + seconds) * 1000;
}

/**
 * Smoothly scrolls to an element with the specified ID.
 *
 * @description
 * This method finds an element by its ID and smoothly scrolls it into view.
 * It uses the native `scrollIntoView` method with smooth behavior.
 *
 * @param {string} id - The ID of the element to scroll to.
 *
 * @example
 * // Scroll to an element with ID "myElement"
 * scrollToElement("myElement");
 *
 * @remarks
 * - This method uses `getElementById` instead of `querySelector` for performance reasons.
 * - The smooth scrolling behavior may not be supported in all browsers.
 *   In unsupported browsers, it will fall back to instant scrolling.
 * - If no element is found with the given ID, this method will do nothing.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView|MDN scrollIntoView}
 */
export const scrollToElement = (id: string): void => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

/**
 * Converts milliseconds to a timespan string.
 * @param {number} milliseconds - The number of milliseconds to convert.
 * @returns {string} The timespan as a string in the format "HH:MM:SS" or "HHH:MM:SS" for spans over 99 hours.
 */
export function convertMillisecondsToTimespanString(
  milliseconds: number,
): string {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours.toString().padStart(hours >= 100 ? 3 : 2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

/**
 * Checks if a given file extension corresponds to an audio or video file type.
 *
 * @param fileExtension - The file extension to check. Can include or exclude the leading dot.
 * @returns True if the extension corresponds to an audio or video file type, false otherwise.
 *
 * @example
 * isAudioVideoFile('mp3');     // true
 * isAudioVideoFile('.mp4');    // true
 * isAudioVideoFile('jpg');     // false
 * isAudioVideoFile('.txt');    // false
 */
const audioVideoExtensions = new Set([
  // Audio
  "mp3",
  "wav",
  "ogg",
  "flac",
  "aac",
  "m4a",
  "wma",
  "aiff",
  "alac",
  // Video
  "mp4",
  "avi",
  "mkv",
  "mov",
  "wmv",
  "flv",
  "webm",
  "mpeg",
  "m4v",
  "3gp",
  "ts",
]);

export function isAudioVideoFile(
  fileExtension: string | null | undefined,
): boolean {
  if (!fileExtension || fileExtension == undefined) {
    return false;
  }

  const normalizedExtension = fileExtension
    .trim()
    .toLowerCase()
    .replace(/^\./, "");
  return audioVideoExtensions.has(normalizedExtension);
}

/**
 * Converts a time string in the format "HH:mm:ss" to seconds.
 * @param timeString - The time string to convert
 * @returns The total number of seconds
 * @example
 * timeStringToSeconds("00:01:30") // 90
 * timeStringToSeconds("01:00:00") // 3600
 */
export function timeSpanToSeconds(timeString: string): number {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

/**
 * Converts a File object to a base64-encoded string.
 * @param file - The File object to convert
 * @returns A Promise that resolves with the base64-encoded string
 * @example
 * const file = new File(["Hello, World!"], "test.txt", { type: "text/plain" });
 * fileToBase64(file).then(base64 => console.log(base64));
 * // Output: data:text/plain;base64,SGVsbG8sIFdvcmxkIQ==
 */
export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.addEventListener("error", error => reject(error));
  });
};
