import { Menu, MenuItems, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { forwardRef, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Profile } from "../../types/platform/Profile";
import Gravatar from "../profile/Gravatar";

interface ProfileMenuProperties {
  profile: Profile;
  isDropDown: boolean;
  onClose?: () => void;
}

interface NavigationItem {
  path: string;
  label: string;
}

const navigationItems: NavigationItem[] = [
  { path: "/outcomes", label: "Outcomes" },
  { path: "/sharing", label: "Sharing" },
  { path: "/profile", label: "Profile" },
];

const mobileLinkStyle =
  "text-md block font-medium rounded-md px-3 py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-syllabyte-dark-card";
const desktopLinkStyle =
  "block px-2 py-2 rounded-md text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-syllabyte-dark-card transition ease-in-out duration-150";

const ProfileAvatar = forwardRef<HTMLDivElement, { profile: Profile }>(
  ({ profile }, reference) => (
    <div
      ref={reference}
      className="text-sm rounded-full ring-2 ring-gray-200 dark:ring-syllabyte-dark-border ring-offset-1 hover:ring-syllabyte-blue dark:hover:ring-syllabyte-blue focus:ring-syllabyte-blue"
    >
      <span className="sr-only">Open profile menu</span>
      <Gravatar
        className="h-10 w-10 rounded-full"
        hash={profile.id}
        title={profile?.name || profile?.email || profile?.id}
      />
    </div>
  )
);

ProfileAvatar.displayName = "ProfileAvatar";

const ProfileInfo: React.FC<{ profile: Profile }> = React.memo(
  ({ profile }) => (
    <div className="flex items-center px-5">
      <div className="flex-shrink-0">
        <Gravatar
          className="h-10 w-10 rounded-full"
          hash={profile.id}
          title={profile?.name || profile?.email || profile?.id}
        />
      </div>
      <div className="ml-3">
        <div className="text-base font-medium text-gray-900 dark:text-white">
          {profile.name}
        </div>
        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
          {profile.email}
        </div>
      </div>
    </div>
  )
);

ProfileInfo.displayName = "ProfileInfo";

const MenuItem = forwardRef<
  HTMLAnchorElement,
  {
    item: NavigationItem;
    onItemClick: (path: string) => void;
    active?: boolean;
  }
>(({ item, onItemClick, active }, reference) => (
  <Menu.Item>
    {({ active: menuActive }) => (
      <NavLink
        ref={reference}
        to={item.path}
        className={clsx(
          desktopLinkStyle,
          active || menuActive ? "bg-gray-100 dark:bg-syllabyte-dark-card" : ""
        )}
        onClick={() => onItemClick(item.path)}
      >
        <p className="text-md font-medium">{item.label}</p>
      </NavLink>
    )}
  </Menu.Item>
));

MenuItem.displayName = "MenuItem";

{
  /*
const MenuDivider: React.FC = () => (
  <hr className="my-2 border-gray-200 dark:border-syllabyte-dark-border" />
);
*/
}

const LogoutButton = forwardRef<
  HTMLButtonElement,
  { onLogout: () => void; isMobile?: boolean }
>(({ onLogout, isMobile }, reference) => (
  <button
    ref={reference}
    onClick={onLogout}
    className={clsx(
      isMobile
        ? mobileLinkStyle
        : "block w-full text-left px-2 py-2 rounded-md text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-syllabyte-dark-card transition ease-in-out duration-150"
    )}
  >
    <p className="text-md font-medium">Logout</p>
  </button>
));

LogoutButton.displayName = "LogoutButton";

export const ProfileMenu: React.FC<ProfileMenuProperties> = React.memo(
  ({ profile, isDropDown, onClose }) => {
    const navigate = useNavigate();
    // const { isDarkMode, toggleDarkMode } = useDarkMode();

    const handleItemClick = useCallback(
      (path: string) => {
        onClose?.();
        navigate(path);
      },
      [onClose, navigate]
    );

    const renderDropdownMenu = () => (
      <div className="hidden md:block z-20">
        <div className="flex items-center">
          <Menu as="div" className="ml-4 relative">
            {({ open }) => (
              <>
                <Menu.Button className="flex items-center">
                  <ProfileAvatar profile={profile} />
                  <ChevronDownIcon
                    className={clsx(
                      "hidden flex-shrink-0 ml-2 h-5 w-5 text-gray-900 dark:text-white md:block",
                      open ? "transform rotate-180" : ""
                    )}
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="origin-top-right absolute right-0 mt-2 w-60 py-4 px-4 rounded-md shadow-lg bg-white dark:bg-syllabyte-dark-card ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {navigationItems.map(item => (
                      <MenuItem
                        key={item.path}
                        item={item}
                        onItemClick={handleItemClick}
                      />
                    ))}
                    {/*
                    <MenuDivider />
                    <button
                      onClick={toggleDarkMode}
                      className="w-full text-left px-2 py-2 rounded-md text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-syllabyte-dark-card transition ease-in-out duration-150"
                    >
                      <p className="text-md font-medium">
                        {isDarkMode ? "Light Mode" : "Dark Mode"}
                      </p>
                    </button>
                    <MenuDivider />
                    */}
                    <LogoutButton onLogout={() => handleItemClick("/logout")} />
                  </MenuItems>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    );

    const renderMobileMenu = () => (
      <div className="pt-4 pb-3 border-t border-gray-200 dark:border-syllabyte-dark-border">
        <ProfileInfo profile={profile} />
        <div className="mt-3 px-2 space-y-1">
          {navigationItems.map(item => (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) =>
                clsx(
                  mobileLinkStyle,
                  isActive ? "bg-gray-100 dark:bg-syllabyte-dark-card" : ""
                )
              }
              onClick={() => onClose?.()}
            >
              {item.label}
            </NavLink>
          ))}
          {/*
          <button onClick={toggleDarkMode} className={mobileLinkStyle}>
            {isDarkMode ? "Light Mode" : "Dark Mode"}
          </button>
          */}
          <LogoutButton onLogout={() => handleItemClick("/logout")} isMobile />
        </div>
      </div>
    );

    return isDropDown ? renderDropdownMenu() : renderMobileMenu();
  }
);

ProfileMenu.displayName = "ProfileMenu";
