import { Outlet } from "react-router-dom";
import { NavItem } from "../../types/platform/NavItem";
import { NavBar } from "../navigation/NavBar";
import { Onboarder } from "../onboarding/Onboarder";
import { Footer } from "./Footer";

export const AppLayout = (): JSX.Element => {
  const navItems: NavItem[] = [
    //{ path: "/workbench", label: "Workbench" },
    { path: "/studio", label: "Studio" },
    { path: "/content", label: "Content" },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Onboarder />
      <NavBar navItems={navItems} fullWidth={true} />
      {/* <UpSellContainer /> */}
      <div className="flex-grow">
        <Outlet />
      </div>
      <Footer variant="thin" />
    </div>
  );
};
